.App {
  text-align: center;
}

body {
  background-color: black;
}

* {
  margin: 0;
  padding: 0;

  -webkit-backface-visibility: hidden !important;
  -moz-backface-visibility: hidden !important;
  -ms-backface-visibility: hidden !important;
  backface-visibility: hidden !important;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
