.button {
  font: var(--primary-font);

  outline: none;
  cursor: pointer;
  background: #fff;
  opacity: 0.95;
  color: #242424;
  padding: 0.65rem;

  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.35rem;
  border: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button:disabled {
  cursor: auto !important;
  border: none;
}

.roundedBtn {
  border-radius: 5rem;
}
.buttonError {
  -webkit-box-shadow: 0px 0px 0px 3px rgb(179, 27, 0);
  box-shadow: 0px 0px 0px 3px rgb(179, 27, 0);
}

.semiRoundedBtn {
  border-radius: 0.4rem;
}

.checkoutButton {
  padding: 0.75rem;
}
.afterPayButton {
  background-color: #ffffff;
}
.afterPayButton:hover {
  background-color: #e2e2e2;
}

.transparentBtn {
  border: thin solid var(--primary);

  background: transparent;
  color: white;
}

.transparentBtn:disabled:hover {
  background: transparent;
  color: white;
}

.soldOutButton {
  cursor: auto !important;
}
.expandedButton {
  width: 100%;
}

@media (hover: hover) {
  .transparentBtn:hover {
    background: #fff;
    opacity: 0.95;
    color: #242424;
  }
  .transparentBtn.soldOutButton:hover {
    background: transparent;
    color: white;
  }

  .chevreButton:hover .buttonChevre {
    padding-left: 0.5rem !important;
  }
  .chevreButton:hover {
    padding-left: 1.5rem !important;
  }
  .checkoutButton:hover {
    background-color: rgb(235, 235, 235);
  }
}

@media screen and (max-width: 519px) {
  .checkoutButton {
    padding: 0.65rem;
  }
}
