.menuContainer {
  pointer-events: auto;
  width: 20rem;
  max-width: 100vw;

  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  position: fixed;
  top: 0rem;
  left: -20rem;
  -webkit-transition: all 0.3s ease-out, height 0s;
  -o-transition: all 0.3s ease-out, height 0s;
  transition: all 0.3s ease-out, height 0s;
}

.menuShow {
  -webkit-transform: translate3d(20rem, 0, 0);
  transform: translate3d(20rem, 0, 0);
}

.outerMenuContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}
.innerMenuContainer {
  position: absolute;
  top: 0;
  left: -5rem;
  right: 5rem;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  background-color: black;
}

.innerMenuSlide {
  -webkit-transition: all 0.3s ease-out 0.1s;
  -o-transition: all 0.3s ease-out 0.1s;
  transition: all 0.3s ease-out 0.1s;

  opacity: 1;
  -webkit-transform: translate3d(5rem, 0, 0);
  transform: translate3d(5rem, 0, 0);
}

.closeMenuButtonConTainer {
  padding-top: 1rem;

  width: 100%;
  height: 4rem;
  padding-bottom: 4rem;
}

.menuRightButton {
  width: 3.3rem;
  height: 3.3rem;
  float: right;
  position: relative;
  cursor: pointer;
  margin-right: -0.35rem;
}

.menuNavContainer {
  width: 100%;
  max-height: 3.05rem;
  border-bottom: 1px solid rgba(150, 150, 150, 0.61);
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
}

.expandShopMenu {
  max-height: 14rem;
}

.expandAboutMenu {
  max-height: 8rem;
}

.menuNavTopLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;

  -webkit-box-pack: justify;

  -ms-flex-pack: justify;

  justify-content: space-between;
  vertical-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.5rem;
  cursor: pointer;
  padding-right: 0.5rem;
  color: rgb(241, 241, 241);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.navMenuSocialContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2rem;
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.showSocialMenu {
  opacity: 1;
  -webkit-transition: all 0.5s ease-out 0.3s;
  -o-transition: all 0.5s ease-out 0.3s;
  transition: all 0.5s ease-out 0.3s;
}
.menuSocialIcon {
  color: rgb(199, 199, 199);
  text-decoration: none;
  font-size: 1.26rem;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menuNavLink {
  font: var(--third-font);
  text-decoration: none;
  opacity: 1;
  font-size: 1.25rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: left;
  text-decoration: none;
  color: inherit;
}
.menuPlus {
  font-size: 2rem;
  width: 1.8rem;
  height: 1.8rem;
  position: relative;
}

.menuSubLink {
  font: var(--secondary-font);
  color: rgb(199, 199, 199);
  min-width: 100%;
  height: 2.5rem;
  display: block;
  font-size: 1rem;
  text-align: left;
  padding-left: 1rem;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  line-height: 2.5rem;
  cursor: pointer;

  text-decoration: none;
}

.menuSectionButton {
  height: 2.2rem;
  display: block;
  text-indent: -9999px;
  float: left;
}
.menuSectionButton:before {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(189, 189, 189);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transition: all 0.3s ease-out 0.1s;
  -o-transition: all 0.3s ease-out 0.1s;
  transition: all 0.3s ease-out 0.1s;

  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.menuSectionButton:after {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(189, 189, 189);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transition: all 0.15s ease-out 0.1s;
  -o-transition: all 0.15s ease-out 0.1s;
  transition: all 0.15s ease-out 0.1s;
}

.collapsedButton.menuSectionButton:before {
  -webkit-transform: rotate(-0deg);
  -ms-transform: rotate(-0deg);
  transform: rotate(-0deg);
  opacity: 0;
}

@media (hover: hover) {
  .menuNavTopLink:hover .menuSectionButton:before,
  .menuNavTopLink:hover .menuSectionButton:after {
    background: black;
  }
  .menuSocialIcon:hover {
    -webkit-box-shadow: 0px 14px 20px 0px rgba(255, 255, 255, 0.15);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.5);
  }
  .menuNavTopLink:hover,
  .menuSubLink:hover {
    color: black;
    background-color: rgb(219, 219, 219);
  }
}

@media screen and (max-width: 25rem) {
  .menuContainer {
    width: calc(100vw - 5rem);
  }
}

@media screen and (max-width: 20rem) {
  .menuContainer {
    width: 100vw;
  }
}

.closeMenuEx {
  height: 2.2rem;
  display: block;
  text-indent: -9999px;
  float: left;
}
.closeMenuEx:before,
.closeMenuEx:after {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(231, 231, 231);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transition: all 0.35s ease-out 0.05s;
  -o-transition: all 0.35s ease-out 0.05s;
  transition: all 0.35s ease-out 0.05s;
  opacity: 1;
}

.closeMenuEx:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.closeMenuEx:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.collapsedButton.closeMenuEx:after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);

  -webkit-transition: all 0.01s ease-out 0.5s;
  -o-transition: all 0.01s ease-out 0.5s;
  transition: all 0.01s ease-out 0.5s;
  opacity: 0.5;
}

.collapsedButton.closeMenuEx:before {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);

  -webkit-transition: all 0.01s ease-out 0.5s;
  -o-transition: all 0.01s ease-out 0.5s;
  transition: all 0.01s ease-out 0.5s;
  opacity: 0.5;
}
