.navBarHidden {
  visibility: hidden;
}

.navbar {
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 5.75rem;
  min-height: 5.75rem;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  max-width: 100vw;
  -webkit-box-flex: 1;

  -ms-flex-positive: 1;

  flex-grow: 1;
}

.freeShippingBanner {
  z-index: 99;

  position: fixed;
  width: 100%;
  min-width: 100%;

  height: 2rem;
  max-height: 2rem;

  color: black;
  background-color: rgb(252, 248, 248);
  top: 0;

  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  overflow: hidden;
  pointer-events: none;
}

.shippingBannerCollapse {
  max-height: 0;
}

.bannerLeft {
  float: left;
  width: 18.33%;
}
.bannerCenter {
  font: var(--third-font);
  float: left;
  width: 63.33%;
  line-height: 2rem;
  text-decoration: none;
  color: black;
  white-space: nowrap;
  pointer-events: auto;
}
.bannerRight {
  margin-right: 1rem;
  float: right;
  width: 18.33%;
  width: 2.1rem;
  position: relative;
  cursor: pointer;
  max-height: inherit;
  pointer-events: auto;
}

.logoImage {
  height: 6rem;
  width: auto;
  opacity: 0.8;
  pointer-events: auto;
}

.cloaseBannerEx {
  height: 3.2rem;

  display: block;
  text-indent: -9999px;
  float: left;
  pointer-events: none;
}
.cloaseBannerEx:before {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.cloaseBannerEx:after {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cartMask {
  pointer-events: auto;

  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.fancyFont {
  font: var(--third-font);
  opacity: 1;
  font-size: 1.1rem;
}

.logo {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cart {
  pointer-events: auto;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;

  cursor: pointer;
  right: 4rem;
  font-size: 1.5rem;
  color: #fff;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: absolute;
}

.notiBadge {
  position: absolute;
  right: -1rem;
  top: -0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  background: #b31e25;
  text-align: center;
  border-radius: 50%;
  color: black;
  font-size: 0.8rem;

  /*   font-size:20px; */
}

.badgeAnimation {
  -webkit-animation: badgeGrow 0.6s ease-in-out;
  animation: badgeGrow 0.6s ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes badgeGrow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes badgeGrow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.dontAnimateNav {
  -webkit-animation: none !important;
  animation: none !important;
  opacity: 1;
}

.menu {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 0.625rem auto 0.625rem auto 0.625rem auto;
  grid-template-columns: repeat(4, auto);
  grid-gap: 0.625rem;
  list-style: none;
  text-align: center;
  left: 1.8rem;
  position: absolute;
  width: 60vw;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.navLink {
  height: 1.25rem;
}

.navLinks {
  pointer-events: auto;

  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  padding: 0.25rem 1rem;
  height: 100%;
  position: relative;
}

.navLinks div:after {
  display: block;
  content: "";
  padding-bottom: 0.05rem;
  border-bottom: solid 1px white;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.navLinks div:hover:after {
  -webkit-transform: scaleX(1.2);
  -ms-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  pointer-events: auto;

  display: none;
}

.flexSupportCheck {
  display: flex;
  flex-direction: column;
  gap: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

menu-icon2 {
  display: none;
}

@media screen and (max-width: 960px) {
  /*ok to delete?*/

  .cart {
    opacity: 1 !important;
    right: 2rem;
  }

  .menu {
    left: -120%;
    opacity: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    left: 1.25rem;
    font-size: 1.8rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 250px) {
  .logo {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .bannerCenter {
    font-size: 0.8rem;
  }
  .bannerRight {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 275px) {
  .bannerRight {
    display: none;
  }
}
