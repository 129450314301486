body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  max-width: 100vw;
  --primary: #fff;
  --btnRadius: 0.2rem;

  --secondary-font-font-style: italic;
  --secondary-font-font-family: "Josefin Sans", sans-serif;
  --secondary-font: var(--secondary-font-font-style) 1rem
    var(--secondary-font-font-family);

  --primary-font-font-style: normal;
  --primary-font-font-family: "Libre Baskerville", serif;
  --primary-font: var(--primary-font-font-style) 1rem
    var(--primary-font-font-family);

  --third-font-font-style: normal;
  --third-font-font-family: "Newsreader", serif;
  --third-font: var(--third-font-font-style) 1rem var(--third-font-font-family);
}
