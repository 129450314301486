.cartContainer {
  pointer-events: auto;
  width: 25rem;
  max-width: 100vw;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  position: fixed;
  top: 0rem;
  right: -25rem;
  opacity: 0.5;

  -webkit-transition: all 0.4s ease-out, height 0s;
  -o-transition: all 0.4s ease-out, height 0s;
  transition: all 0.4s ease-out, height 0s;
}

.cartContainer.cartShow {
  opacity: 1;
  -webkit-transform: translate3d(-25rem, 0, 0);
  transform: translate3d(-25rem, 0, 0);
}

.outerCartContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}
.innerCartContainer {
  position: absolute;
  top: 0;
  left: 5rem;
  right: -5rem;
  bottom: 0;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.innerCartSlide {
  -webkit-transition: all 0.4s ease-out 0.1s;
  -o-transition: all 0.4s ease-out 0.1s;
  transition: all 0.4s ease-out 0.1s;

  -webkit-transform: translate3d(-5rem, 0, 0);
  transform: translate3d(-5rem, 0, 0);
}

.sideCartTitle {
  color: white;
}

.cartLinkName {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.emptyCartTitle {
  font: var(--third-font);

  font-size: 1.8rem;

  opacity: 0;
  -webkit-transition: all 0.75s ease 0.25s !important;
  -o-transition: all 0.75s ease 0.25s !important;
  transition: all 0.75s ease 0.25s !important;
}

.showCartTitle {
  opacity: 1 !important;
  -webkit-transition: all 0.4s ease-out !important;
  -o-transition: all 0.4s ease-out !important;
  transition: all 0.4s ease-out !important;
}

.closeCartButton {
  color: white;
  width: 100%;
  text-align: left;
  padding-bottom: 1rem;
  font-size: 1.8rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: black;
  padding-top: 1rem;
  padding-left: 0.25rem;
  z-index: 10;
  padding-bottom: 2.5rem;
  margin-bottom: 1rem;
}
.sideCartItemContainer {
  height: 100%;
  padding-right: 0.5rem;
  margin-bottom: 10rem;
  overflow-y: scroll;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  position: relative;
  padding-bottom: 2rem;
  overscroll-behavior: contain;
}

.fullWidthCartVertSpacer {
  height: 2rem;
  opacity: 0;
  pointer-events: none;
}
.alignleft {
  float: left;
  width: 13.5%;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.aligncenter {
  font: var(--third-font);
  float: left;
  width: 73%;
  color: rgb(231, 231, 231);
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.5rem;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out !important;
  -o-transition: all 0.4s ease-out !important;
  transition: all 0.4s ease-out !important;
}
.alignright {
  float: left;
  width: 13.5%;
  text-align: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fadeInSideCartItems {
  opacity: 1 !important;
  -webkit-transition: opacity 0.75s ease 0.1s !important;
  -o-transition: opacity 0.75s ease 0.1s !important;
  transition: opacity 0.75s ease 0.1s !important;
}

.sideCartItems {
  padding-right: 1rem;
  padding-left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1rem;
}

.name {
  font: var(--primary-font);
  margin-top: 0.1rem;
  font-size: 0.9rem;
  text-align: left;
  min-width: 10rem;
  padding-bottom: 3rem;
}

.name .portion {
  margin-top: 0.2rem;
  font-size: 0.8rem;
}

.delete {
  font: var(--secondary-font);

  cursor: pointer;
  font-size: 0.95rem;
  margin-left: 0.1rem;

  font-weight: 700;
  color: #ca585e;
  width: 3rem;
  margin-top: 0.5rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.delete:hover {
  color: #ac3238;
}

.priceQty {
  margin-right: 1rem;
}

.itemPrice {
  font: var(--secondary-font);

  font-size: 1.1rem;

  position: relative;
  margin-top: 0.3rem;
}

.itemImage {
  height: 4rem;
  width: auto;
}

.subTotalContainer {
  border-top: 1px solid rgba(163, 162, 162, 0);
  background-color: rgb(0, 0, 0);
  width: 100%;
  max-height: 15rem;
  position: absolute;
  -webkit-transition: all 0.01s ease 0.5s;
  -o-transition: all 0.01s ease 0.5s;
  transition: all 0.01s ease 0.5s;
}

@-moz-document url-prefix() {
  .subTotalContainer {
    bottom: 0;
  }
}

.subTotalBorder {
  opacity: 1;
  border-color: rgba(255, 255, 255, 0.61);
  -webkit-transition: all 0.4s ease-out !important;
  -o-transition: all 0.4s ease-out !important;
  transition: all 0.4s ease-out !important;
}

.subTotalPriceContainer {
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  opacity: 0;
  -webkit-transition: opacity 0.01s ease 0.5s;
  -o-transition: opacity 0.01s ease 0.5s;
  transition: opacity 0.01s ease 0.5s;
}

.showSubTotal {
  opacity: 1;
  -webkit-transition: all 0.4s ease-out !important;
  -o-transition: all 0.4s ease-out !important;
  transition: all 0.4s ease-out !important;
}
.subTotalTag {
  font: var(--primary-font);
  font-size: 1.25rem;
}

.subTotalPrice {
  font: var(--secondary-font);
  font-size: 1.25rem;
}
.checkoutButtonContainer {
  padding: 1rem;
  width: 100%;
}
.cartButtonContainer {
  position: relative;
  max-height: 3.2rem;
  height: 3.2rem;
  min-height: 3.2rem;
}

.innerCartButtonContainer {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.01s ease 0.5s;
  -o-transition: all 0.01s ease 0.5s;
  transition: all 0.01s ease 0.5s;
}

.fadeInCheckoutButton {
  -webkit-animation: cartFadeIn2 0.5s ease-in forwards;
  animation: cartFadeIn2 0.5s ease-in forwards;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  will-change: transform;
}

.fadeInEditButton {
  -webkit-animation: cartFadeIn 0.45s ease-out forwards;
  animation: cartFadeIn 0.45s ease-out forwards;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  will-change: transform;
}

@-webkit-keyframes cartFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0.5rem, -1rem, 0);
    transform: translate3d(0.5rem, -1rem, 0);
  }
  70% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes cartFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0.5rem, -1rem, 0);
    transform: translate3d(0.5rem, -1rem, 0);
  }
  70% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cartFadeIn2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0.5rem, -1.5rem, 0);
    transform: translate3d(0.5rem, -1.5rem, 0);
  }
  60% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes cartFadeIn2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0.5rem, -1.5rem, 0);
    transform: translate3d(0.5rem, -1.5rem, 0);
  }
  60% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}

.cartButtonSapcer {
  width: 100%;
  height: 1rem;
}

.quantityMod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  height: 1.4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 3.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.quantityMod .qtyText,
.quantityMod button {
  font: var(--secondary-font);

  border-color: transparent;
  border-style: solid;
  color: rgb(255, 255, 255);
  width: 1.2rem;
  padding: 0.2rem;

  font-size: 1.3rem;
  text-align: center;
  background: transparent;
}

.quantityMod button:focus {
  outline: none !important;
}

.quantityMod button:disabled {
  color: rgb(114, 114, 114);
}

.quantityMod button {
  cursor: pointer;
  margin-top: -0.3rem;
}

.quantityMod .qtyText {
  font-size: 1rem;
  margin-top: 0.1rem;
  pointer-events: none;
  border: none;
}

.quantityMod button:hover {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0.95;
}

.leftQty {
  margin-bottom: 0.1rem;
  -webkit-transform: scaleX(1.6);
  -ms-transform: scaleX(1.6);
  transform: scaleX(1.6);
}

@media screen and (max-width: 30rem) {
  .cartContainer {
    width: calc(100vw - 5rem);
  }
  .fullWidthCartVertSpacer {
    display: none;
  }
  .closeCartButton {
    display: block;
  }
  .sideCartItems {
    padding: 0;
  }
  .sideCartItemContainer {
    padding-right: 0;
  }
}

@media screen and (max-width: 25rem) {
  .cartContainer {
    width: 100vw;
  }

  .innerCartSlide {
    -webkit-animation: none;
    animation: none;
  }
}
@media screen and (max-width: 320px) {
  .sideCartItemImage {
    display: none;
  }
}

.closeCartButtonEx {
  height: 2.2rem;
  display: block;
  text-indent: -9999px;
  float: left;
}
.closeCartButtonEx:before {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(231, 231, 231);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transition: all 0.35s ease-out 0.1s;
  -o-transition: all 0.35s ease-out 0.1s;
  transition: all 0.35s ease-out 0.1s;
  opacity: 1;

  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.closeCartButtonEx:after {
  content: "";
  width: 55%;
  height: 1px;
  background: rgb(231, 231, 231);
  position: absolute;
  top: 48%;
  left: 22%;

  -webkit-transition: all 0.2s ease-out 0.1s;
  -o-transition: all 0.2s ease-out 0.1s;
  transition: all 0.2s ease-out 0.1s;
  opacity: 1;

  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.collapsedButton.closeCartButtonEx:after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);

  -webkit-transition: all 0.01s ease-out 0.5s;
  -o-transition: all 0.01s ease-out 0.5s;
  transition: all 0.01s ease-out 0.5s;
  opacity: 0.5;
}

.collapsedButton.closeCartButtonEx:before {
  -webkit-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  transform: rotate(-35deg);

  -webkit-transition: all 0.01s ease-out 0.5s;
  -o-transition: all 0.01s ease-out 0.5s;
  transition: all 0.01s ease-out 0.5s;
  opacity: 0.5;
}
