#parallax {
  -webkit-perspective: 1px;
  perspective: 1px;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.parallaxLayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.parallaxHeroContent {
  -webkit-transform: translateZ(-0.25px) scale(1.25);
  transform: translateZ(-0.25px) scale(1.25);

  z-index: 3;
  opacity: 0;
  -webkit-animation: parallaxFadeIn 2s ease-out 4s;
  animation: parallaxFadeIn 2s ease-out 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@-webkit-keyframes parallaxFadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes parallaxFadeIn {
  100% {
    opacity: 1;
  }
}

.parallaxScrollDown {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 3rem;
  position: absolute;
  color: rgba(255, 255, 255, 0);
  font-size: 2.5rem;

  width: 2rem;
  height: 2rem;

  -webkit-animation: scrollButtonIn 0.75s ease-in-out forwards;
  animation: scrollButtonIn 0.75s ease-in-out forwards;
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}

.heroButtonContainer {
  padding-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  width: min(100%, 40rem);
  max-width: 100%;
  gap: 2rem;
  padding: 1rem;
  padding-left: 0;
  padding-right: 0;
}

.heroButton {
  width: 20rem;
  max-width: 100%;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@-webkit-keyframes scrollButtonIn {
  100% {
    color: rgba(255, 255, 255, 0.425);

    -webkit-transform: translate3D(-1rem, 2rem, 0);

    transform: translate3D(-1rem, 2rem, 0);
    cursor: pointer;
  }
}

@keyframes scrollButtonIn {
  100% {
    color: rgba(255, 255, 255, 0.425);

    -webkit-transform: translate3D(-1rem, 2rem, 0);

    transform: translate3D(-1rem, 2rem, 0);
    cursor: pointer;
  }
}

#introVideo {
  width: auto;

  height: 700px;
  min-width: 100vw;
  margin-top: -55px;
  z-index: -10;
  overflow-x: hidden;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);

  -webkit-animation: introVid 4.5s ease-out 0.5s;

  animation: introVid 4.5s ease-out 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
}

@media screen and (min-height: 700px) {
  #introVideo {
    width: auto;

    height: auto;
    min-height: 100vh;
    min-width: 100vw;
  }
}

@-webkit-keyframes introVid {
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.25;
    z-index: -1000 !important;
  }
}

@keyframes introVid {
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.25;
    z-index: -1000 !important;
  }
}

.video_contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateZ(-0.5px) scale(1.5);
  transform: translateZ(-0.5px) scale(1.5);
}

@-moz-document url-prefix() {
  .video_contain,
  .parallaxHeroContent {
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
.heroContent {
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  margin: auto 0;
  top: max(32.5%, 7rem);
  left: 0;
  right: 0;
  z-index: 10;
  pointer-events: auto;
  padding-bottom: 1rem;
}

.heroContent .heroTitle {
  font: var(--primary-font);
  color: #fff;
  font-size: 2.5rem;
  margin-left: 0.15rem;
  text-indent: -0.15rem;
  z-index: 4;
  margin-bottom: 2.5rem;
}
.heroContent .heroSubtitle {
  font: var(--secondary-font);

  color: #fff;
  font-size: 1.2rem;
  width: min(28vw, 560);

  z-index: 4;
  margin-bottom: 1.5rem;
}

.buttonChevre {
  padding-left: 0rem;

  -webkit-transition: all 0.4s ease-out;

  -o-transition: all 0.4s ease-out;

  transition: all 0.4s ease-out;
  font-size: 1.18rem;
}

@supports (-ms-ime-align: auto) {
  .heroContent {
    top: 32.5%;
  }

  .heroButtonContainer {
    width: 40rem;
  }
}

@media screen and (min-width: 2000px) {
  .heroContent {
    text-align: center;

    width: 100%;

    left: 0;
  }
}

@media screen and (max-width: 1060px) {
  .steakName {
    display: none;
  }
  .steakDisplay {
    width: 40vw;
  }

  .heroContent .heroTitle {
    font-size: max(4.2vw, 1.5rem);
  }
}

@media screen and (max-width: 800px) {
  .heroButtonContainer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: min(28rem, 100vw);
    gap: 0;
  }
  .heroButton {
    padding-top: 1rem;
    width: 100%;
  }
  .parallaxHeroContent {
    opacity: 1 !important;
  }

  .heroContent .heroTitle {
    margin-bottom: 1rem;
    max-width: 100%;
  }

  .heroContent .heroSubtitle {
    margin: 0 auto 2rem auto;
    width: 40rem;
    max-width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .heroContent {
    width: 100%;
    max-width: 100vw;
    padding: 2rem;
    text-align: center;
    top: 8.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    left: 0;
    z-index: 4;
  }
  .heroContent {
    top: 10rem;
  }

  .parallaxHeroContent,
  .video_contain {
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}

@media screen and (max-height: 550px) {
  .parallaxScrollDown {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .parallaxScrollDown {
    display: none;
  }

  #introVideo {
    -webkit-animation: none;
    animation: none;
    opacity: 0.5;
  }

  @supports (-ms-ime-align: auto) {
    .heroButtonContainer {
      width: 100%;
    }
  }
}
